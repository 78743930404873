<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2022-02-10 09:27:44
 * @LastEditors: ------
 * @LastEditTime: 2022-02-14 09:23:16
-->
<template>
  <div class="bulu">
    <van-nav-bar
      style="background: linear-gradient(-90deg, #84f5de, #4cc5f8)"
      :title="'补录'"
      left-text="返回"
      left-arrow
      placeholder
      @click-left="$router.go(-1)"
    />
    <van-notice-bar mode="closeable"
      >* 请确认此订单已由用户线下支付成功</van-notice-bar
    >
    <div class="card">
      <van-tabs type="card" color="#0DACF4" @click="change">
        <van-tab title="在线支付">
          <van-form @submit="onSubmit">
            <van-field
              v-model="offlinePaySerialNo"
              name="offlinePaySerialNo"
              label="支付流水号："
              input-align="right"
              placeholder="请输入支付流水号"
              border
              size="large"
              :rules="[{ required: true }]"
            />
            <van-field
              name="offlinePayChannel"
              label="支付方式："
              input-align="right"
              size="large"
            >
              <template #input>
                <van-radio-group
                  v-model="offlinePayChannel"
                  direction="horizontal"
                >
                  <van-radio name="1">微信支付</van-radio>
                  <van-radio name="2">支付宝支付</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              readonly
              clickable
              name="offlinePayDate"
              :value="value"
              label=" 支付时间："
              input-align="right"
              placeholder="请选择支付日期 >"
              :rules="[{ required: true }]"
              size="large"
              @click="showCalendar = true"
            />
            <van-calendar
              v-model="showCalendar"
              @confirm="onConfirm"
              :min-date="minDate"
              :max-date="maxDate"
              color="#0DACF4"
            />
            <div class="btn">
              <van-button
                round
                size="large"
                native-type="submit"
                color="linear-gradient(-90deg, #84F5DE, #4CC5F8)"
              >
                提交
              </van-button>
              <van-button
                round
                size="large"
                color="#CACFD7"
                @click="$router.go(-1)"
                >返回</van-button
              >
            </div>
          </van-form>
        </van-tab>
        <van-tab title="现金支付">
          <van-form @submit="onSubmit1">
            <van-field
              v-model="offlinePayPayee"
              name="offlinePayPayee"
              label="收款人姓名："
              placeholder="请输入收款人姓名"
              input-align="right"
              :rules="[{ required: true }]"
            />
            <van-field
              v-model="offlinePayAmount"
              name="offlinePayAmount"
              label="收款金额："
              type="number"
              input-align="right"
              placeholder="请输入收款金额"
              :rules="[{ required: true }]"
            />
            <van-field
              readonly
              clickable
              name="offlinePayDate"
              :value="value"
              label="收款日期："
              input-align="right"
              placeholder="请选择收款日期 >"
              :rules="[{ required: true }]"
              size="large"
              @click="showCalendar = true"
            />
            <van-calendar
              v-model="showCalendar"
              @confirm="onConfirm"
              :min-date="minDate"
              :max-date="maxDate"
              color="#0DACF4"
            />
            <div class="btn">
              <van-button
                round
                size="large"
                native-type="submit"
                color="linear-gradient(-90deg, #84F5DE, #4CC5F8)"
              >
                提交
              </van-button>
              <van-button
                round
                size="large"
                color="#CACFD7"
                @click="$router.go(-1)"
                >返回</van-button
              >
            </div>
          </van-form>
        </van-tab>
        <van-tab title="银行转账">
          <!-- 打款银行：
打款金额：
打款流水号：
打款时间： -->
          <van-form @submit="onSubmit2">
            <van-field
              readonly
              clickable
              name="offlinePayBank"
              label="打款银行："
              :value="bank"
              input-align="right"
              placeholder="请选择打款银行 >"
              :rules="[{ required: true }]"
              @click="showPicker = true"
            />
            <van-popup v-model="showPicker" position="bottom">
              <van-picker
                show-toolbar
                :columns="['建设银行上海民生路支行（0025）']"
                @confirm="onConfirm1"
                @cancel="showPicker = false"
              />
            </van-popup>
            <van-field
              v-model="offlinePayBankAmount"
              name="offlinePayBankAmount"
              label="打款金额："
              type="number"
              input-align="right"
              placeholder="请输入打款金额"
              :rules="[{ required: true }]"
            />
            <van-field
              v-model="offlinePayBankSerialNo"
              name="offlinePayBankSerialNo"
              label="打款流水号："
              input-align="right"
              placeholder="请输入打款流水号"
              :rules="[{ required: true }]"
            />
            <van-field
              readonly
              clickable
              name="offlinePayDate"
              :value="value"
              label="收款日期："
              input-align="right"
              placeholder="请选择收款日期 >"
              :rules="[{ required: true }]"
              size="large"
              @click="showCalendar = true"
            />
            <van-calendar
              v-model="showCalendar"
              @confirm="onConfirm"
              :min-date="minDate"
              :max-date="maxDate"
              color="#0DACF4"
            />
            <div class="btn">
              <van-button
                round
                size="large"
                native-type="submit"
                color="linear-gradient(-90deg, #84F5DE, #4CC5F8)"
              >
                提交
              </van-button>
              <van-button
                round
                size="large"
                color="#CACFD7"
                @click="$router.go(-1)"
                >返回</van-button
              >
            </div>
          </van-form>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { PostPaySupplement } from "@/api/index.js";
import { Toast } from "vant";

export default {
  name: "",
  data() {
    return {
      offlinePayChannel: "1",
      offlinePaySerialNo: "",
      offlinePayPayee: "",
      offlinePayAmount: "",
      offlinePayBankAmount: "",
      offlinePayBankSerialNo: "",
      value: "",
      bank: "",
      showCalendar: false,
      showPicker: false,
      minDate: new Date(new Date().setDate(new Date().getDate() - 90)),
      maxDate: new Date(),
    };
  },
  methods: {
    change() {
      this.value = "";
    },
    onConfirm(times) {
      var date = new Date(times);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      // var h = date.getHours();
      // var minute = date.getMinutes();
      // var seconds = date.getSeconds();
      // minute = minute < 10 ? "0" + minute : minute;
      // seconds = seconds < 10 ? "0" + seconds : seconds;
      let time = y + "-" + m + "-" + d + " ";

      this.value = time;
      this.showCalendar = false;
    },
    onSubmit(values) {
      values.offlinePayType = 1;
      this.postInfo(values);
    },
    onSubmit1(values) {
      values.offlinePayType = 2;
      this.postInfo(values);
    },
    onSubmit2(values) {
      values.offlinePayType = 3;

      this.postInfo(values);
    },
    onConfirm1(value) {
      this.bank = value;
      this.showPicker = false;
    },
    async postInfo(values) {
      values.flax = "11";
      values.orderPayId = this.$route.query.id;
      values.offlinePayDate = values.offlinePayDate + "00:00:00";
      let { data } = await PostPaySupplement(values);
      if (data.success) {
        Toast.success(data.message);
        this.$router.go(-1);
      } else {
        Toast.fail(data.message);
      }
    },
  },
};
</script>

<style scoped lang="less">
.bulu {
  height: 100vh;

  background: #fffcfa;
  /deep/ .van-nav-bar {
    .van-nav-bar__title {
      color: #fff;
    }
  }

  /deep/ .van-nav-bar__left {
    .van-icon,
    .van-nav-bar__text {
      color: #fff;
    }
  }
  .card {
    margin-top: 22px;
  }
  .btn {
    padding: 30px;
    button {
      margin-top: 20px;
    }
  }
}
</style>
